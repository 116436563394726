import { v4 } from 'uuid';

export enum QlikIdentities {
  unfiltered,

  // The primary QlikIdentity session
  // This will be used for sessions that need to share field filtering information
  primary,
}

export type QlikIdentitiesTypeDef = keyof typeof QlikIdentities;

/**
 * This code assumes that TypeScript will always translate enums to objects.
 * This code uses `Object.values` instead of `Object.keys` so that
 * the integers generated by TypeScript are not stringified.
 * This removes additional processing later
 */
const _identities: QlikIdentitiesTypeDef[] = Object.values(
  QlikIdentities
) as QlikIdentitiesTypeDef[];


/**
 * Developers can use QlikIdentities.* or get identities by the string name
 */
export const QlikIdentityRegistry = new Map<
  QlikIdentitiesTypeDef | QlikIdentities,
  string
>();

// Populate the map
_identities.forEach((id) => {
  const uid = v4();

  // Save the key and the enum value as the same id
  if (!QlikIdentityRegistry.has(id)) {
    // TypeScript enums are internally stored as: {"a": 0, 0: "a"}
    QlikIdentityRegistry.set(id, uid);
    QlikIdentityRegistry.set(QlikIdentities[id], uid);
  }
});
