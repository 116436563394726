import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { API, Auth } from 'aws-amplify';
import * as enigmaAPI from 'enigma.js';
import schema from 'enigma.js/schemas/12.20.0.json';
import SenseUtilities from 'enigma.js/sense-utilities';
import './qlikAPI_typedef';
import { QlikAPI } from './qlikAPI_typedef';
import { tryLogin } from './qlikHelper';
import {
  QlikIdentities,
  QlikIdentitiesTypeDef,
  QlikIdentityRegistry,
} from './QlikIdentityRegistry';

export const qlikWebIntegrationId = 'd3naKMIduK1jDFZU56eoeIrKkbop_6pF';
export const qlikTenantDomain = 'vyy68efkbcls0av.us.qlikcloud.com';
export const qlikIdentity = QlikIdentityRegistry.get(QlikIdentities.primary);

export var cachedQixApp = {
  globalApp: null,
  globalSession: null,
  appId: null,
  networkChangeEventHandler: false,
};

/**
 * Check if the user is logged into QLik
 * @returns true if the user is logged in, false otherwise
 */
export async function isLoggedIn() {
  return fetch(`https://${qlikTenantDomain}/api/v1/users/me`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'qlik-web-integration-id': qlikWebIntegrationId,
    },
  }).then((response) => {
    return response.status === 200;
  });
}

/**
 * Generate a signed JWT to be used with QLik
 * @param {CognitoUserSession} cognitoUser
 * @returns Signed JWT
 */
export async function getSignedQLikJWT(cognitoUser: CognitoUserSession) {
  return await API.get('dev-foursightauthhelper', '/dev/qlikjwt', {
    headers: {
      token: cognitoUser.getIdToken().getJwtToken(),
    },
  });
}

/**
 * Attempt to login to QLik with a signed JWT
 * @param {string} token JWT to use as a Bearer token when logging in with QLik
 * @returns
 */
export async function loginToQlikWithToken(token: string) {
  return await fetch(
    `https://${qlikTenantDomain}/login/jwt-session?qlik-web-integration-id=${qlikWebIntegrationId}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        'qlik-web-integration-id': qlikWebIntegrationId,
      },
    }
  );
}

/**
 * Fetch all high-level Qlik information accessible for the current user.
 * This includes:
 *  - The spaces a user has access to.
 *  - The apps in each space.
 *  - The sheets in each app.
 *  - The objects in each sheet.
 */
export async function getQlikOverview(): Promise<QlikAPI.Space[]> {
  const cognitoUser = await Auth.currentSession();
  const qlikJwtToken = await getSignedQLikJWT(cognitoUser);
  return await API.get('dev-foursightappid', `/dev/appids`, {
    queryStringParameters: {
      jwtToken: qlikJwtToken,
    },
  }).catch((err) => {
    console.log(err);
    err.constructor.name = 'XMLHttpRequestError';
    err.message += ` Entire error log: ${JSON.stringify(err)}`;
    throw err;
  });
}

/**
 * Get all spaces in the tenant that the user has access to
 * @returns List of spaces
 */
export async function getSpaces() {
  return (await getQlikOverview()).map((elem) => ({
    name: elem.name,
    id: elem.id,
  }));
}

/**
 * @deprecated
 *
 * Get all apps in a space that the user has access to
 * @param {string} spaceId An ID of a QLik space
 * @returns {Promise<{name: string, id: string}[]>} List of apps
 */
export async function getApps(
  spaceId: string
): Promise<{ name: string; id: string }[]> {
  const overview = await getQlikOverview();
  return (overview.find((space) => space.id === spaceId) as any).apps.map(
    (elem) => ({ name: elem.name, id: elem.id })
  );
}

/**
 * Log out of Qlik
 * @returns
 */
export async function logout() {
  return await fetch(`https://${qlikTenantDomain}/logout`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'qlik-web-integration-id': qlikWebIntegrationId,
    },
  });
}

/**
 * Fetch a CSRF token from Qlik
 * This is used to establish a connection with Qlik QIX api
 */
export async function getCSRF() {
  const response = await fetch(
    `https://${qlikTenantDomain}/api/v1/csrf-token`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'qlik-web-integration-id': qlikWebIntegrationId,
      },
    }
  );

  return response.headers.get('qlik-csrf-token');
}

/**
 * Establish a connection to the QIX API through a websocket
 * @param {string} appId A valid Qlik app id
 * @returns
 */
export async function getQixAPI(
  appId: string,
  sessionName: QlikIdentities = QlikIdentities.primary
): Promise<EngineAPI.IApp> {
  if (
    true /* Temporarily disabled to investigate if aborted request errors are reduced */ ||
    Object.keys(cachedQixApp).some((key) => cachedQixApp[key] == null) ||
    cachedQixApp.appId !== appId
  ) {
    let session = await createEnigmaSession(appId, sessionName);

    session.on('closed', (error) =>
      qlikContextEndEvent('Session', 'closed', error)
    );
    session.on('suspended', (error) =>
      qlikContextEndEvent('Session', 'suspended', error)
    );

    const data = await session.open().catch(async (error) => {
      error.message = `Failed to open a Qlik enigma session. ${
        error.message || ''
      }`.trim();
      throw error;
    });

    // Create the app object for communication with QIX
    const app = await data.openDoc(appId);
    app.on('closed', () => qlikContextEndEvent('App', 'closed', undefined));

    // cachedQixApp.globalApp = app;
    // cachedQixApp.appId = appId;
    // cachedQixApp.globalSession = session;

    if (!cachedQixApp.networkChangeEventHandler) {
      // When the browser disconnects close the session to trigger manual error handling
      window.addEventListener('offline', () => {
        session.close();
      });

      // When the browser reconnects try creating the session again
      window.addEventListener('online', () => {
        window.location.reload();
      });
      cachedQixApp.networkChangeEventHandler = true;
    }
    return app;
  }
}

export async function createEnigmaSession(
  appId: string,
  sessionName: QlikIdentities | QlikIdentitiesTypeDef
): Promise<QlikAPI.EnigmaSession> {
  // Throw an error if the session name is not registered
  if (!QlikIdentityRegistry.has(sessionName))
    throw new Error(
      `createEnigmaSession: sessionName "${sessionName}" (or "${QlikIdentities[sessionName]}") is not registered.`
    );

  const csrf = await getCSRF();
  const url = SenseUtilities.buildUrl({
    host: qlikTenantDomain,
    identity: QlikIdentityRegistry.get(sessionName),
    appId,
    urlParams: {
      'qlik-web-integration-id': qlikWebIntegrationId,
      'qlik-csrf-token': csrf,
    },
  });

  // Establish a socket
  let session = enigmaAPI.create({
    schema,
    url,
    createSocket: (url: string | URL) => new WebSocket(url),
  });
  return session;
}

/**
 * An error handler for qlik enigma events
 * @param {'App' | 'Session'} context The context where the error occured
 * @param {string} event The event that occured
 * @param {*} status The error object returned by Enigma
 */
function qlikContextEndEvent(
  context: 'App' | 'Session',
  event: string,
  status?: any
) {
  console.error(`${context} connection has been ${event}. Status:`, status);

  // Broadcast the message to any listeners
  // This is used by the useIsEnigmaConnected hook
  window.dispatchEvent(new Event(`enigma:${event}`));
  tryLogin().catch((error) => {
    console.error(error);
  });
}

/**
 * @deprecated
 *
 * Get all bookmarks of an app
 * @param {string} appId A valid Qlik app id
 * @returns
 */
export async function getAppBookmarks(appId: string) {
  const app = await getQixAPI(appId, QlikIdentities.primary);

  return await app.getBookmarks({
    qOptions: {
      qTypes: ['bookmark'],
      qData: {
        title: '/qMetaDef/title',
        description: '/qMetaDef/description',
        sheetId: '/sheetId',
        selectionFields: '/selectionFields',
        creationDate: '/creationDate',
      },
    },
  } as any);
}

/**
 * @deprecated
 *
 * Get the sheet ids of an app
 * This currently works by finding sheets marked by bookmarks
 * @param {string} appId A valid Qlik app id
 * @returns {Promise<{title: string, sheetId: string}[]>} A list of sheet ids
 */
export async function getBookmarks(
  appId: string
): Promise<{ title: string; sheetId: string }[]> {
  const bookmarks = (await getAppBookmarks(appId)) as any;
  return bookmarks.map((bookmark) => bookmark.qData);
}
